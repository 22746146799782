import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import _ from 'underscore';

const normalizeText = (text) => {
    return text.replace(/-.,/, ' ').trim().toLowerCase();
};

const isPartialMatch = (needles, haystack) => {
    if (!needles || !needles.length) {
        return true;
    }

    if (!haystack || !haystack.length) {
        return true;
    }

    needles = _.uniq(needles.sort(), true);
    haystack = _.uniq(haystack.sort(), true);

    return needles.some((needle) => {
        return haystack.indexOf(needle) !== -1;
    });
};

const isPriceMatch = (needle, product) => {
    if (!needle) {
        return true;
    }

    if (needle === 'free') {
        return product.free;
    }

    if (needle === 'sale') {
        return product.on_sale;
    }

    return true;
};

const isTextMatch = (needles, product) => {
    if (!needles || !needles.length) {
        return true;
    }

    const haystack = normalizeText((product.product_name || '') + ' ' + (product.short_description || ''));

    return normalizeText(needles).split(/\s+/).every((needle) => {
        return haystack.indexOf(needle) !== -1;
    });
};

export function* setFilter({ payload }) {
    const inputs = yield select(state => state.catalog && state.catalog.inputs);
    const filters = inputs.reduce((filters, input) => {
        let values = [];
        input.count = 0;

        input.items.forEach(item => {
            if (item.checked) {
                input.count++;
                if (item.value.match(/,/)) {
                    item.value.split(',').forEach(v => {
                        values.push(v);
                    });
                } else {
                    values.push(item.value);
                }
            }
        });

        if (values.length) {
            filters[input.key] = values;
        }

        return filters;
    }, {});

    if (payload && payload.search) {
        filters.search = payload.search;
    }

    yield put({
        type: 'catalog:update',
        payload: {
            filters: filters,
            inputs: inputs,
        },
    });

    yield call(filter);
}

export function* filter() {
    const { products, inputs, filters: { search }} = yield select(state => state.catalog);

    const filters = inputs.reduce((filters, input) => {
        let values = [];
        input.count = 0;

        input.items.forEach((item) => {
            if (item.checked && item.value != 'all') {
                input.count++;

                if (item.value.match(/,/)) {
                    item.value.split(',').forEach((v) => {
                        values.push(v);
                    });
                } else {
                    values.push(item.value);
                }
            }
        });

        if (values.length) {
            filters[input.key] = values;
        }

        return filters;
    }, {});

    let filteredProducts = products.filter((product) => {
        return (
            isPartialMatch(filters.divination, product.divination) &&
            isPartialMatch(filters.topic, product.topic) &&
            isTextMatch(search, product) &&
            isPriceMatch(search, product)
        );
    });

    yield put({
        type: 'catalog:update',
        payload: {
            filteredProducts: filteredProducts,
        },
    });

    let filters_array = [];

    if (filters.divination) {
        filters_array.push('divination=' + filters.divination.join(',').replace(/^,/, ''));
    }

    if (filters.topic) {
        filters_array.push('topic=' + filters.topic.join(',').replace(/^,/, ''));
    }

    if (search) {
        filters_array.push('search=' + encodeURIComponent(search));

        if (['free', 'sale'].includes(search)) {
            filters_array.push('price=' + encodeURIComponent(search));
        }
    }

    //document.getElementById('catalog-content').scrollIntoView()
    window.scrollTo(0, 0);
}

export function* root() {
    yield all([
        takeEvery('catalog:filter', filter),
        takeEvery('catalog:set-filter', setFilter),
    ]);
}
